
button {
  padding: 0.5em 1em;
  background-color: var(--grey);
  border-radius: 5px;
  border-color: var(--orange);
}

.buy-button {
    background-color: lightblue;
    font-weight: bold;
    text-transform: uppercase;
}

.select-button,
.sign-up-button,
.login-button,
.quantity-button,
.toggleButton {
    background-color: lightblue;
}


.confirm-payment-button,
.confirm-buy-button,
.confirm-booking-button {
    color: var(--white);
    background-color: var(--blue);
}


button + button {
    margin-left: 2em;
}