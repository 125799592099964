
.buy-product-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-around;
  /*padding: 10px;*/
  flex-grow: 0;
  flex-shrink: 1;
  flex-wrap: wrap;
}

.form-container-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px;
}